import React from "react"

import Layout from "../components/layout"
import styles from "./index.module.css"

const IndexPage = () => (
  <Layout>
    <link
      href="https://fonts.googleapis.com/css?family=Playfair+Display&display=swap"
      rel="stylesheet"
    />
    <h1>Doula Services</h1>
  </Layout>
)

export default IndexPage
